import axios from 'axios';

// Definiere die Basis-URL für die API
const API_URL = 'https://lowork.lokisoft.net/api/';

// Erstelle einen Axios-Client mit der Basis-URL und Standard-Headern
const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Füge die benutzerdefinierten Methoden hinzu
apiClient.startTimer = async function(zeitErfassung) {
  const response = await this.post('/timers/start', zeitErfassung);
  return response.data; // Erwartet Rückgabe von ZeitErfassung
};

apiClient.pauseTimer = async function(zeitErfassungId) {
  const response = await this.post(`/timers/pause/${zeitErfassungId}`);
  return response.data; // Erwartet Rückgabe von ZeitErfassung
};

apiClient.resetTimer = async function(zeitErfassungId) {
  const response = await this.post(`/timers/reset/${zeitErfassungId}`);
  return response.data; // Erwartet Rückgabe von ZeitErfassung
};

apiClient.getTime = async function(zeitErfassungId) {
  const response = await this.get(`/timers/time/${zeitErfassungId}`);
  return response.data; // Erwartet Rückgabe von ZeitErfassung
};

apiClient.login = async function(username, password) {
  const response = await this.post('/login', { username, password });
  return response.data;
};

apiClient.getTodos = async function(config) {
  const response = await this.get('/todos', config);
  return response.data; // Erwartet Rückgabe von Todo[]
};

apiClient.createTodo = async function(todo, config) {
  const response = await this.post('/todos', todo, config);
  return response.data; // Erwartet Rückgabe von Todo
};

apiClient.updateTodo = async function(todoId, todo, config) {
  const response = await this.put(`/todos/${todoId}`, todo, config);
  return response.data; // Erwartet Rückgabe von Todo
};

apiClient.deleteTodo = async function(todoId, config) {
  const response = await this.delete(`/todos/${todoId}`, config);
  return response.data; // Erwartet Rückgabe von void
};

// Exportiere `apiClient`
export default apiClient;
