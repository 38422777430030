import { createStore } from 'vuex';
import apiClient from '@/services/api';
import axios, { AxiosError } from 'axios';

interface Timer {
  gestoppteZeit: number;
  intervalId: number | null;
}

interface Todo {
  id: number;
  user_id: number;
  title: string;
  description: string;
  creation_date: string;
  due_date: string;
}

interface User {
  username: string;
  token: string;
}

interface State {
  user: User | null;
  timers: { [key: string]: Timer };
  todos: Todo[];
}

// Definieren Sie die Typen für commit
type Commit = (mutation: string, payload?: any) => void;

const store = createStore<State>({
  state: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null,
    timers: {},
    todos: []
  },
  mutations: {
    setUser(state: State, user: User) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    },
    clearUser(state: State) {
      state.user = null;
      localStorage.removeItem('user');
      delete apiClient.defaults.headers.common['Authorization'];
    },
    setTodos(state: State, todos: Todo[]) {
      state.todos = todos;
    },
    addTodo(state: State, todo: Todo) {
      state.todos.push(todo);
    },
    updateTodo(state: State, updatedTodo: Todo) {
      const index = state.todos.findIndex(todo => todo.id === updatedTodo.id);
      if (index !== -1) {
        state.todos.splice(index, 1, updatedTodo);
      }
    },
    deleteTodo(state: State, todoId: number) {
      state.todos = state.todos.filter(todo => todo.id !== todoId);
    },
    startTimer(state: State, timerId: string) {
      if (!state.timers[timerId]) {
        state.timers[timerId] = { gestoppteZeit: 0, intervalId: null };
      }
      if (state.timers[timerId].intervalId === null) {
        state.timers[timerId].intervalId = window.setInterval(() => {
          state.timers[timerId].gestoppteZeit += 1;
        }, 1000);
      }
    },
    pauseTimer(state: State, timerId: string) {
      const timer = state.timers[timerId];
      if (timer && timer.intervalId !== null) {
        clearInterval(timer.intervalId);
        timer.intervalId = null;
      }
    },
    resetTimer(state: State, timerId: string) {
      const timer = state.timers[timerId];
      if (timer) {
        if (timer.intervalId !== null) {
          clearInterval(timer.intervalId);
          timer.intervalId = null;
        }
        timer.gestoppteZeit = 0;
      }
    }
  },
  
  actions: {
    async startTimer({ commit }: { commit: Commit }, zeitErfassung: any) {
      try {
        const response = await apiClient.startTimer(zeitErfassung);
        if (response.success) {
          commit('startTimer', zeitErfassung.id);
        } else {
          console.error('Fehler beim Starten des Timers:', response.message);
        }
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.error('Fehler beim Starten des Timers:', axiosError.message || error);
      }
    },
    async pauseTimer({ commit }: { commit: Commit }, zeitErfassungId: number) {
      try {
        const response = await apiClient.pauseTimer(zeitErfassungId);
        if (response.success) {
          commit('pauseTimer', zeitErfassungId);
        } else {
          console.error('Fehler beim Pausieren des Timers:', response.message);
        }
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.error('Fehler beim Pausieren des Timers:', axiosError.message || error);
      }
    },
    async resetTimer({ commit }: { commit: Commit }, zeitErfassungId: number) {
      try {
        const response = await apiClient.resetTimer(zeitErfassungId);
        if (response.success) {
          commit('resetTimer', zeitErfassungId);
        } else {
          console.error('Fehler beim Zurücksetzen des Timers:', response.message);
        }
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.error('Fehler beim Zurücksetzen des Timers:', axiosError.message || error);
      }
    },
    async login({ commit }: { commit: Commit }, credentials: { username: string; password: string }) {
      try {
        const response = await apiClient.login(credentials.username, credentials.password);
        if (response.success) {
          const user: User = {
            username: credentials.username,
            token: response.data?.token || ''
          };
          commit('setUser', user);
          console.log('Authorization Header:', apiClient.defaults.headers.common['Authorization']);
        } else {
          console.error('Fehler beim Einloggen:', response.message);
        }
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.error('Fehler beim Einloggen:', axiosError.message || error);
      }
    },
    logout({ commit }: { commit: Commit }) {
      commit('clearUser');
    },
    async fetchTodos({ commit }: { commit: Commit }) {
      try {
        const response = await apiClient.getTodos();
        if (response.success) {
          commit('setTodos', response.data || []);
        } else {
          console.error('Fehler beim Abrufen der Todos:', response.message);
          alert(response.message);
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          console.error('Fehler beim Abrufen der Todos:', error.message);
          if (error.response && error.response.status === 401) {
            commit('clearUser');
            alert('Deine Sitzung ist abgelaufen, bitte melde dich erneut an.');
          }
        } else {
          console.error('Unbekannter Fehler:', error);
        }
      }
    },
    async createTodo({ commit }: { commit: Commit }, todo: Todo) {
      try {
        const response = await apiClient.createTodo(todo);
        if (response.success) {
          commit('addTodo', response.data || todo);
          alert('To-Do erfolgreich erstellt');
        } else {
          console.error('Fehler beim Erstellen des To-Dos:', response.message);
          alert(response.message);
        }
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.error('Fehler beim Erstellen des To-Dos:', axiosError.message || error);
      }
    },
    async updateTodo({ commit }: { commit: Commit }, todo: Todo) {
      try {
        const response = await apiClient.updateTodo(todo.id, todo);
        if (response.success) {
          commit('updateTodo', response.data || todo);
          alert('To-Do erfolgreich aktualisiert.');
          return response.data;
        } else {
          console.error('Fehler beim Aktualisieren des To-Dos:', response.message);
          alert(response.message);
        }
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.error('Fehler beim Aktualisieren des To-Dos:', axiosError.message || error);
      }
    },
    async deleteTodo({ commit }: { commit: Commit }, todoId: number) {
      try {
        const response = await apiClient.deleteTodo(todoId);
        if (response.success) {
          commit('deleteTodo', todoId);
          alert('To-Do erfolgreich gelöscht.');
        } else {
          console.error('Fehler beim Löschen des To-Dos:', response.message);
          alert(response.message);
        }
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.error('Fehler beim Löschen des To-Dos:', axiosError.message || error);
      }
    }
  },

  getters: {
    isAuthenticated: (state: State): boolean => !!state.user,
    getUser: (state: State): User | null => state.user,
    getTime: (state: State) => (timerId: string): number => {
      const timer = state.timers[timerId];
      return timer ? timer.gestoppteZeit : 0;
    },
    getTodos: (state: State): Todo[] => state.todos,
    getTodoById: (state: State) => (id: number): Todo | undefined => {
      return state.todos.find(todo => todo.id === id);
    }
  }
});

export default store;
