<template>
  <div class="todo-view">
    <TodoList />
  </div>
</template>

<script>
import TodoList from '../components/TodoList.vue'

export default {
  components: {
    TodoList
  }
}
</script>
