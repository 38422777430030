<template>
  <div class="todo-container">
    <h1 class="todo-title">
      To-Do Liste
    </h1>

    <!-- Button zum Erstellen einer neuen To-Do -->
    <button
      class="new-todo-button"
      @click="toggleForm"
    >
      {{ showForm ? 'Schließen' : 'Neue Aufgabe erstellen' }}
    </button>

    <!-- Erstellungsformular anzeigen/ausblenden -->
    <form
      v-if="showForm"
      class="todo-form"
      @submit.prevent="editingTodo ? handleUpdateTodo() : handleCreateTodo()"
    >
      <input
        v-model="newTodo.title"
        placeholder="Titel"
        required
        class="todo-input"
      >
      <textarea
        v-model="newTodo.description"
        placeholder="Beschreibung"
        class="todo-textarea"
      />
      <input
        v-model="newTodo.creationDate"
        type="date"
        required
        class="todo-input"
      >
      <input
        v-model="newTodo.dueDate"
        type="date"
        required
        class="todo-input"
      >
      <button
        type="submit"
        class="todo-button"
      >
        {{ editingTodo ? 'Aufgabe aktualisieren' : 'Aufgabe hinzufügen' }}
      </button>
    </form>

    <!-- To-Do Liste -->
    <div
      v-for="todo in todos"
      :key="todo.id"
      :style="getTodoStyle(todo)"
      class="todo-item"
    >
      <!-- To-Do Titel anklicken, um Details anzuzeigen -->
      <h3
        class="todo-item-title"
        @click="toggleTodoDetails(todo)"
      >
        {{ todo.title }}
      </h3>

      <!-- To-Do Details und Bearbeiten/Erledigt Buttons -->
      <div
        v-if="todo.showDetails"
        class="todo-details"
      >
        <p>{{ todo.description }}</p>
        <p>Erstellt am: {{ todo.creationDate }}</p>
        <p>Enddatum: {{ todo.dueDate }}</p>

        <!-- Button: Erledigt (löschen) -->
        <button
          class="todo-complete-button"
          @click="completeTodo(todo.id)"
        >
          Erledigt
        </button>

        <!-- Button: Bearbeiten -->
        <button
          class="todo-edit-button"
          @click="editTodoForUpdate(todo)"
        >
          Bearbeiten
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      showForm: false,
      newTodo: {
        title: '',
        description: '',
        creationDate: '',
        dueDate: ''
      },
      editingTodo: null // Referenz auf das zu bearbeitende To-Do
    };
  },
  computed: {
    ...mapGetters(['getTodos']),
    todos() {
      return this.getTodos;
    }
  },
  mounted() {
    this.fetchTodos();
  },
  methods: {
    ...mapActions(['fetchTodos', 'createTodo', 'deleteTodo', 'updateTodo']),
    
    toggleForm() {
      this.showForm = !this.showForm;
      this.editingTodo = null;
      if (!this.showForm) {
        this.resetForm();
      }
    },

    async handleCreateTodo() {
      try {
        const response = await this.createTodo(this.newTodo);
        if (response && response.todo) {
          console.log('Neues Todo erfolgreich erstellt:', response.todo);
          this.resetForm();
          this.showForm = false;
        } else {
          console.error('Keine gültige Antwort vom Server erhalten.');
        }
      } catch (error) {
        console.error('Fehler beim Erstellen des To-Dos:', error);
        alert('Es gab ein Problem beim Erstellen des To-Dos.');
      }
    },

    async handleUpdateTodo() {
  try {
    const updatedTodo = { ...this.newTodo, id: this.editingTodo.id };
    const response = await this.updateTodo(updatedTodo.id, updatedTodo);

    if (response && response.id) {
      console.log('Todo erfolgreich aktualisiert:', response);
      this.resetForm();
      this.showForm = false;
    } else {
      console.error('Keine gültige Antwort vom Server erhalten oder das aktualisierte Todo hat keine ID.');
      alert('Fehler beim Aktualisieren des To-Dos: Keine gültige Antwort vom Server erhalten.');
    }
  } catch (error) {
    console.error('Fehler beim Aktualisieren des To-Dos:', error);
    alert('Es gab ein Problem beim Aktualisieren des To-Dos.');
  }
}
,

    resetForm() {
      this.newTodo = {
        title: '',
        description: '',
        creationDate: '',
        dueDate: ''
      };
      this.editingTodo = null;
    },

    toggleTodoDetails(todo) {
      todo.showDetails = !todo.showDetails;
    },

    async completeTodo(todoId) {
      try {
        await this.deleteTodo(todoId);
      } catch (error) {
        console.error('Fehler beim Löschen des To-Dos:', error);
        alert('Es gab ein Problem beim Löschen des To-Dos.');
      }
    },

    editTodoForUpdate(todo) {
      this.newTodo = { ...todo };
      this.showForm = true;
      this.editingTodo = todo;
    },

    getTodoStyle(todo) {
      const dueDate = new Date(todo.dueDate);
      const currentDate = new Date();
      const timeDiff = (dueDate - currentDate) / (1000 * 3600 * 24);

      if (timeDiff >= 3) {
        return { border: '2px solid green' };
      } else if (timeDiff < 3 && timeDiff >= 1) {
        return { border: '2px solid orange' };
      } else {
        return { border: '2px solid red' };
      }
    }
  }
};
</script>

<style scoped>
.todo-container {
  color: #979797;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 2rem;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}

.todo-title {
  font-size: 2rem;
  color: silver;
  margin-bottom: 1.5rem;
}

.new-todo-button {
  margin-bottom: 1rem;
  background-color: var(--mcolor);
  color: var(--scolor);
  padding: 0.75rem;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.new-todo-button:hover {
  background-color: var(--tcolor);
  color: #fff;
}

.todo-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.todo-input, .todo-textarea {
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%;
}

.todo-button {
  background-color: var(--mcolor);
  color: var(--scolor);
  padding: 0.75rem;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
}

.todo-button:hover {
  background-color: var(--tcolor);
  color: #fff;
}

.todo-item {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border 0.3s ease;
}

.todo-item-title {
  font-size: 1.25rem;
  cursor: pointer;
  color: #333;
}

.todo-details {
  margin-top: 0.75rem;
  color: #666;
}

.todo-complete-button, .todo-edit-button {
  background-color: #f5f5f5;
  color: #333;
  padding: 0.5rem;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 0.5rem;
  transition: background-color 0.3s ease;
}

.todo-complete-button:hover, .todo-edit-button:hover {
  background-color: #e0e0e0;
}
</style>
