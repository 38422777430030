<template>
  <div class="loginView">
    <login-component />
  </div>
</template>

<script>
import LoginComponent from '../components/LoginComponent.vue'

export default {
  name: 'LoginView',
  components: {
    'login-component': LoginComponent
  }
}
</script>

  <style scoped>
  .loginView {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  </style>
