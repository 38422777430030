<template>
  <div class="login-wrapper">
    <div class="login-container">
      <h1>Login</h1>
      <form @submit.prevent="handleLogin">
        <div class="input-group">
          <label for="username">Benutzername:</label>
          <input
            id="username"
            v-model="username"
            type="text"
            required
          >
        </div>
        <div class="input-group">
          <label for="password">Passwort:</label>
          <input
            id="password"
            v-model="password"
            type="password"
            required
          >
        </div>
        <button
          type="submit"
          class="login-button"
          :disabled="loading"
        >
          <span v-if="loading">Lade...</span>
          <span v-else>Login</span>
        </button>
        <div
          v-if="errorMessage"
          class="error"
        >
          {{ errorMessage }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/api';

export default {
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      errorMessage: ''
    };
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      this.errorMessage = '';
      try {
        const response = await apiClient.post('/login', {
          username: this.username,
          password: this.password
        });
        console.log('Login erfolgreich:', response.data);

        // Speichern des Tokens
        const token = response.data.token;
        localStorage.setItem('token', token);

        // Setze den Authorization-Header für zukünftige API-Aufrufe
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        this.$router.push('/'); // Weiterleitung nach erfolgreichem Login
      } catch (error) {
        this.errorMessage = 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
        console.error('API-Fehler:', error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
/* Dein bestehendes CSS bleibt unverändert */
.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
  background: linear-gradient(135deg, var(--tcolor), rgba(41, 50, 60, 0.8));
  perspective: 1000px;
}

.login-container {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 60px;
  width: 300px;
  text-align: center;
  transform: rotateY(0deg) rotateX(0deg);
  transition: transform 0.5s;
}

.login-container:hover {
  transform: rotateY(10deg) rotateX(10deg);
}

h1 {
  color: white;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  color: white;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  color: var(--vcolor);
}

.input-group input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.5);
}

.login-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--tcolor);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.login-button:hover {
  background-color: var(--scolor);
  transform: scale(1.05);
}

.error {
  color: red;
  margin-top: 10px;
}
</style>
