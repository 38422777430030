<template>
  <nav>
    <router-link to="/">
      Home
    </router-link> |
    <router-link to="/time-management">
      Time Management
    </router-link> |
    <router-link to="/Todo">
      To Do
    </router-link> |
    <router-link to="/posts">
      Info
    </router-link>
  </nav>
  <router-view />
</template>

<style lang="scss">
:root {
  --mcolor: #000000; /* color: var(--mcolor); */
  --scolor: #e8aba8; /* color: var(--scolor); */
  --tcolor: #a44b51; /* color: var(--tcolor); */
  --vcolor: #323a43; /* color: var(--vcolor); */
  --titel1: 25px; /* font-size: var(--titel1); */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url("../src/assets/img/bg5.webp"); /* Pfad zu deinem Bild */
  background-size: cover; /* Sorgt dafür, dass der Hintergrund den gesamten Bereich abdeckt */
  background-repeat: no-repeat;
  background-position: center; /* Zentriert das Hintergrundbild */
  height: 100vh; /* Setzt die Höhe des Elements, sodass es den gesamten Bildschirm einnimmt */
  width: 100vw; /* Setzt die Breite des Elements, sodass es die gesamte Breite einnimmt */
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Verhindert Scrollbars, falls das Bild oder der Inhalt größer als der Bildschirm sind */
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
