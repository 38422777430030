<template>
  <div class="timeManagement">
    <div class="filler1" />
    <h1>This is a Time Management page</h1>
    <div class="flex-container">
      <TimerComponent timer-id="timer1" />
      <TimerComponent timer-id="timer2" />
      <TimerComponent timer-id="timer3" />
      <TimerComponent timer-id="timer4" />
      <TimerComponent timer-id="timer5" />
      <TimerComponent timer-id="timer6" />
      <TimerComponent timer-id="timer7" />
      <TimerComponent timer-id="timer8" />
    </div>
  </div>
  <div class="menu-button-container">
    <router-link
      to="/"
      class="btn1"
    >
      Menü
    </router-link>
  </div>
</template>

<script>
import TimerComponent from '../components/TimerComponent.vue'

export default {
  name: 'TimeManagementView',
  components: {
    TimerComponent
  }
}
</script>

  <style scoped>
  .body {
    margin: 0;
    padding: 0;
  }

  .flex-container {
    display: flex;
    flex-direction: row; /* Elemente nebeneinander */
    justify-content: center; /* Zentriert horizontal */
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 5rem; /* Abstand zwischen den umgebrochenen Zeilen verkleinern */
    height: 50vh; /* Stellt sicher, dass der Container die volle Bildschirmhöhe einnimmt */
    margin-top: 8rem;
    margin-bottom: 2rem;
  }

  .timeManagement {
    color: #979797;
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    min-block-size: 100vh;
    position: relative;
  }

  .h1 {
    font-size: 40px;
    color: silver;
    margin: 0;
  }

  .filler1 {
    height: 5rem;
  }

  .text-border {
    border: groove 4px silver;
    padding: 10px; /* Fügt etwas Platz innerhalb des Borders hinzu */
  }

  .btn1 {
    background-color: var(--mcolor); /* Tiefe, samtige Schwarze */
    color: var(--scolor); /* angenehmes Rosa für den Text */
    padding: 0.4em 0.9em; /* Anpassbar nach Bedarf */
    border: 5em; /* Rand */
    cursor: pointer; /* Cursor ändert sich zu einer Hand, um Interaktivität zu signalisieren */
    border-radius: 8px; /* Leicht abgerundete Ecken */
    outline: none;
    transition: background-color 0.3s ease, color 0.3s ease; /* Weicher Übergang für Hover-Effekte */
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438); /* Subtiler Schatteneffekt */
    -webkit-transform: translate(0);
    transform: translate(0);
    -webkit-transition: box-shadow 0.25s;
    transition: box-shadow 0.25s;
    font-size: 12px;
    margin: 0.4rem;
    z-index: 50;
    text-decoration: none;
  }
  .btn1 .text {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(45deg, #4568dc, #b06ab3);
  }
  .btn1:after {
    content: "";
    border-radius: 8px;
    position: absolute;
    margin: 4px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: #0e0e10;
  }
  /* Hover-Effekt */
  .btn1:hover {
    background-color: var(--tcolor); /* Etwas helleres Pink beim Hover */
    box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
    color: #fff; /* Textfarbe wechselt zu Weiß */
  }
  .btn1:hover .text {
    background-color: var(--tcolor); /* Etwas helleres Pink beim Hover */
  }

  .menu-button-container {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent; /* Oder jede gewünschte Farbe */
    padding: 1rem; /* Gibt ein wenig Abstand zum Rand */
  }
  </style>
