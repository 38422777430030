import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TimeManagementView from '../views/TimeManagementView.vue'
import LoginView from '@/views/LoginView.vue'
import TodoView from '@/views/TodoView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/time-management',
    name: 'time-management',
    component: TimeManagementView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: true }
  },
  {
    path: '/Todo',
    name: 'Todo',
    component: TodoView,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
