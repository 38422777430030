<template>
  <div class="zeitErfassung">
    <div class="zeitAnzeige">
      {{ zeitAnzeige }}
    </div>
    <div class="buttons1">
      <button
        class="button btn1"
        @click="start"
      >
        Start
      </button>
      <button
        class="button bupa1 btn1"
        @click="pause"
      >
        Pause
      </button>
      <button
        class="button burest1 btn1"
        @click="reset"
      >
        Reset
      </button>
    </div>

    <div
      v-if="!eingabeBestaetigt"
      id="gName"
      class="gName"
    >
      <label for="gemeindetxt">Gemeinde:</label>
      <input
        id="gemeindetxt"
        v-model="gemeinde"
        class="gemin"
        type="text"
        @keyup.enter="bestaetigeEingabe"
      >
    </div>
    <div class="buttons2">
      <button
        class="button sbm1 btn1"
        @click="bestaetigeEingabe"
      >
        Los
      </button>
      <button
        class="button clr1 btn1"
        @click="neueEingabe"
      >
        Neu
      </button>
      <button
        class="button ttime1 btn1"
        @click="ticketstd"
      >
        Zeit
      </button>
    </div>

    <div id="tckt">
      {{ gemeinde }}{{ gemeinde ? ':' : '' }} {{ ticketText }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    timerId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      gemeinde: '',
      eingabeBestaetigt: false
    }
  },
  computed: {
    ...mapGetters(['getTime']),
    zeitAnzeige () {
      const gestoppteZeit = this.getTime(this.timerId) || 0
      const sekunden = Math.floor(gestoppteZeit % 60)
      const minuten = Math.floor((gestoppteZeit / 60) % 60)
      const stunden = Math.floor((gestoppteZeit / 3600) % 24)
      return `${stunden < 10 ? '0' + stunden : stunden}:${minuten < 10 ? '0' + minuten : minuten}:${sekunden < 10 ? '0' + sekunden : sekunden}`
    },
    ticketText () {
      const gestoppteZeit = this.getTime(this.timerId) || 0
      const intervallAnzahl = Math.max(Math.floor(gestoppteZeit / (15 * 60)), 1)
      const ticketWert = Math.min(intervallAnzahl * 0.25, 2.0)
      return `Zeit: ${ticketWert.toFixed(2)}`
    }
  },
  methods: {
    ...mapActions(['startTimer', 'pauseTimer', 'resetTimer']),
    
    // Methode für den Start-Button
    async start () {
      try {
        // Startet den Timer über die Vuex-Aktion
        await this.startTimer(this.timerId);
      } catch (error) {
        // Wenn ein Fehler auftritt, wird er in der Konsole ausgegeben
        console.error('Fehler beim Starten des Timers:', error);
      }
    },
    
    // Methode für den Pause-Button
    async pause () {
      try {
        // Pausiert den Timer über die Vuex-Aktion
        await this.pauseTimer(this.timerId);
      } catch (error) {
        // Wenn ein Fehler auftritt, wird er in der Konsole ausgegeben
        console.error('Fehler beim Pausieren des Timers:', error);
      }
    },
    
    // Methode für den Reset-Button
    async reset () {
      try {
        // Setzt den Timer über die Vuex-Aktion zurück
        await this.resetTimer(this.timerId);
      } catch (error) {
        // Wenn ein Fehler auftritt, wird er in der Konsole ausgegeben
        console.error('Fehler beim Zurücksetzen des Timers:', error);
      }
    },
    
    // Methode für die Eingabebestätigung
    bestaetigeEingabe () {
      this.eingabeBestaetigt = true;
    },
    
    // Methode für das Zurücksetzen der Eingabe
    neueEingabe () {
      this.eingabeBestaetigt = false;
      this.gemeinde = '';
    }
  }

}
</script>

  <style>
  .zeitErfassung {
    margin-bottom: 1rem;
  }

  .body {
    margin: 0;
    padding: 0;
  }

  .flex-container {
    display: flex;
    flex-direction: row; /* Elemente nebeneinander */
    justify-content: center; /* Zentriert horizontal */
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 5rem; /* Abstand zwischen den umgebrochenen Zeilen verkleinern */
    height: 50vh; /* Stellt sicher, dass der Container die volle Bildschirmhöhe einnimmt */
    margin-top: 8rem;
    margin-bottom: 2rem;
  }

  .timeManagement {
    color: #979797;
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    min-block-size: 100vh;
    position: relative;
  }

  .h1 {
    font-size: 40px;
    color: silver;
    margin: 0;
  }

  .filler1 {
    height: 5rem;
  }

  .text-border {
    border: groove 4px silver;
    padding: 10px; /* Fügt etwas Platz innerhalb des Borders hinzu */
  }

  .btn1 {
    background-color: var(--mcolor); /* Tiefe, samtige Schwarze */
    color: var(--scolor); /* angenehmes Rosa für den Text */
    padding: 0.4em 0.9em; /* Anpassbar nach Bedarf */
    border: 5em; /* Rand */
    cursor: pointer; /* Cursor ändert sich zu einer Hand, um Interaktivität zu signalisieren */
    border-radius: 8px; /* Leicht abgerundete Ecken */
    outline: none;
    transition: background-color 0.3s ease, color 0.3s ease; /* Weicher Übergang für Hover-Effekte */
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438); /* Subtiler Schatteneffekt */
    -webkit-transform: translate(0);
    transform: translate(0);
    -webkit-transition: box-shadow 0.25s;
    transition: box-shadow 0.25s;
    font-size: 12px;
    margin: 0.4rem;
    z-index: 50;
    text-decoration: none;
  }
  .btn1 .text {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(45deg, #4568dc, #b06ab3);
  }
  .btn1:after {
    content: "";
    border-radius: 8px;
    position: absolute;
    margin: 4px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: #0e0e10;
  }
  /* Hover-Effekt */
  .btn1:hover {
    background-color: var(--tcolor); /* Etwas helleres Pink beim Hover */
    box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
    color: #fff; /* Textfarbe wechselt zu Weiß */
  }
  .btn1:hover .text {
    background-color: var(--tcolor); /* Etwas helleres Pink beim Hover */
  }

  .menu-button-container {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent; /* Oder jede gewünschte Farbe */
    padding: 1rem; /* Gibt ein wenig Abstand zum Rand */
  }
  </style>
